"use strict";

const videogularDependencies = [
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'com.2fdevs.videogular.plugins.poster'
];

angular
    .module("informaApp", [
        "ngCookies",
        "ngRoute",
        "rzModule",
        "ngSanitize",
        "ui.select",
        "datePicker",
        "gridshore.c3js.chart",
        "angularMoment",
        "infinite-scroll",
        "rt.debounce",
        'ngFileUpload',
        ...videogularDependencies
    ])
    .constant("d3", d3)
    .constant("c3", c3)
    .config(['$locationProvider', '$routeProvider', '$httpProvider', function ($locationProvider, $routeProvider, $httpProvider) {
        $locationProvider.html5Mode({enabled: true, requireBase: false});

        $routeProvider
            .when("/login", {
                template: "<user-authentication></user-authentication>"
            })
            .when("/login/callback", {
                template: "<user-authentication></user-authentication>"
            })
            .when('/email-verification', {
                template: '<email-verification></email-verification>'
            })
            .when('/forbidden', {
                templateUrl: 'views/auth/forbidden.html'
            })
            .when('/error', {
                templateUrl: 'views/internal-error.html'
            })
            .when('/logout', {
                template: '<logout></logout>'
            })
            .when('/logout/redirect', {
                template: '<logout-redirect></logout-redirect>'
            })
            .when("/chart", {})
            .when("/predictive", {})
            .when('/trends-view', {})
            .when("/table/:searchUuid?", {})
            .when("/searches", {
                templateUrl: "components/user-searches/user-searches.ptl.html",
                controller: "UserSearchesCtrl"
            })
            .when("/company-profile", {})
            .when("/gantt-chart", {})
            .when("/comparison", {})
            .when("/summary", {})
            .when("/link/:uuid", {
                templateUrl: "components/table/table.ptl.html",
                controller: "TableCtrl",
                isLink: true
            })
            .when("/admin/:section?/:id?", {
                templateUrl: "components/admin/admin.ptl.html",
                controller: "AdminCtrl",
                isAdmin: true,
                reloadOnSearch: false,
                resolve: {
                    userData: ['$route', 'AdminUserResolver', function($route, AdminUserResolver) {
                        var section = $route.current.params.section;

                        if (!section || section === 'users') {
                            return AdminUserResolver();
                        }
                    }]
                },
            })
            .when('/account', {
                template: '<user-account></user-account>'
            })
            .when("/faq", {
                templateUrl: "components/faq/faq.ptl.html",
                controller: "FaqCtrl"
            })
            .when('/coverage-universe', {
                template: '<disease-view diseases="$resolve.diseases"></disease-view>',
                resolve: {
                    diseases: ['DiseaseService', function(DiseaseService) {
                        return DiseaseService.getDiseaseHierarchy();
                    }],
                }
            })
            .when("/cookie-policy", {
                templateUrl: "components/info/cookies/cookies.ptl.html",
                controller: "LayoutCtrl"
            })
            .when("/cookie-list", {
                templateUrl: "components/info/cookies/cookie-list.ptl.html",
                controller: "LayoutCtrl"
            })
            .when("/new-features/:id?", {
                templateUrl: "components/new-feature-view/template.html",
                controller: "NewFeatureViewController",
                resolve: {
                    data: ['$route', 'NewFeatureViewResolver', function($route, NewFeatureViewResolver) {
                        const featureId = Number($route.current.params.id);

                        return NewFeatureViewResolver(featureId);
                    }]
                },
            })
            .otherwise({redirectTo: "table"});

        $httpProvider.interceptors.push(['$q', '$rootScope', 'ConstantsSvc', function ($q, $rootScope, ConstantsSvc) {
            return {
                "request": function (config) {
                    function getCookie(name) {
                        var matches = document.cookie.match(new RegExp(
                            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
                        ));
                        return matches ? decodeURIComponent(matches[1]) : undefined;
                    }

                    config.headers.token = getCookie("token");

                    return config || $q.when(config);
                },
                "response": function (response) {
                    return response || $q.when(response);
                },
                'responseError': function (rejection) {
                    var data = rejection.data;

                    if (data instanceof ArrayBuffer) {
                        var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
                        data = JSON.parse(decodedString);
                    }

                    if (rejection.status === 403 || rejection.status === 401) {
                        $rootScope.$broadcast(ConstantsSvc.EVENTS.RedirectToLogoutBy403, data);
                    }

                    return $q.reject(rejection);
                }
            };
        }]);
    }])
    .run(['$route', '$rootScope', '$location', function ($route, $rootScope, $location) {
        var original = $location.path;
        $location.path = function (path, reload) {
            if (reload === false) {
                var lastRoute = $route.current;
                var un = $rootScope.$on('$locationChangeSuccess', function () {
                    $route.current = lastRoute;
                    un();
                });
            }
            return original.apply($location, [path]);
        };
    }])
    .run(['RzSliderOptions', function (RzSliderOptions) {
        RzSliderOptions.options();
    }])
    .run((DateExtensions, ArrayExtension, WindowExtension) => {
        DateExtensions.setup();
        ArrayExtension.setup();
        WindowExtension.setup();
    })
    .run(["$rootScope", "$location", "$timeout", "AuthenticationService", 'AppcuesService', 'ConstantsSvc', function ($rootScope, $location, $timeout, AuthenticationService, AppcuesService, ConstantsSvc) {

        $rootScope.$on("$routeChangeStart", function (event, next, prev) {

            const isRouteWithoutPermission = ConstantsSvc.routesWithoutPermissions
                .some(x => $location.url().startsWith(x));

            if (!AuthenticationService.isLoggedIn()) {
                const relativeUrl =  $location.url();

                if (relativeUrl !== '/' && !isRouteWithoutPermission) {
                    const fullUtl = $location.$$url;
                    $location.path(ConstantsSvc.urls.login).search({back: relativeUrl !== '/table' ? fullUtl : null});
                }
            }

            if (isRouteWithoutPermission) {
                $('body').addClass('login');
            } else {
                $('body').removeClass('login');
            }
        }); 

        $rootScope.$on("$includeContentLoaded", function(event, templateName){
            showHideInformaTopRibbon();
        });

        $rootScope.$on("$routeChangeSuccess", function (event) {
            $timeout(function () {
                showHideInformaTopRibbon()
            });
        });

        $rootScope.$on('$locationChangeSuccess', function () {
            AppcuesService.restart();
        });
    }]);
